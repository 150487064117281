import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';


@Injectable({
  providedIn: 'root'
})
export class LocalstorageService {

  constructor() { }

  async clear() {
    await Preferences.clear();
  }

  async set(key: string, value: string) {
    // console.log({ key, value });
    await Preferences.set({ key, value });
  }

  async get(key: string): Promise<{ value: any }> {
    const item = await Preferences.get({ key: key });
    return item;
  }

}


