import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  async txtMailNewPass(data) {
    let txtContent = `
      <tr>
        <td class="mcnTextContent"
            style="mso-line-height-rule: exactly; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; word-break: break-word; color: #2a2a2a; font-family: Martel Sans, sans-serif; font-size: 16px; line-height: 150%; text-align: center; padding-top:9px; padding-right: 18px; padding-bottom: 9px; padding-left: 18px;"
            valign="top">
            <h1 class="null"
                style="color: #2a2a2a; font-family: Martel Sans, sans-serif; font-size: 32px; font-style: normal; font-weight: bold; line-height: 125%; letter-spacing: 2px; text-align: center; display: block; margin: 0; padding: 0">
                Hola
            </h1>
            <h2 class="null"
                style="color: #2a2a2a; font-family: Martel Sans, sans-serif; font-size: 18px; font-style: normal; line-height: 125%; letter-spacing: 1px; text-align: center; display: block; margin: 0; padding: 0">
                ${data.user_nom}
                <br>
                Tenemos una informacion importante para ti:
            </h2>
            <br><br>
            <div
              style="font-size: 19px !important; text-align: justify !important; padding: 10px !important">
              Este correo se generó porque solicitaste la recuperación de tu contraseña de acceso para tu app helpy.
              <br><br>
              Para ingresar a tu app, ingresa con la siguiente clave temporal:
              <div style="font-size: 22px; font-weight: bolg; font-family: Helvetica, Arial, sans-serif;  text-decoration: none;  text-decoration: none; padding: 15px 25px; border-radius: 2px; border: 1px solid #fed149; display: inline-block;">
                ${data.clave}
              </div>
              <br>
              Una vez ingreses exitosamente a tu app, por favor cambia tu contraseña desde la opción llamada <b>Mi Perfil</b>
              <br><br>
              <p style="margin: 0;">
                En Helpy nunca te pediremos que compartas estos códigos con alguien más.
              </p>
              <br><br><br>
              <h2 style="font-size: 13px; font-weight: 400; color: #111111; margin: 0;">
                  © ${data.year}. Helpy Bank, Cali, Colombia.
                  <br>
                  En Helpy Bank nunca te pediremos información personal o datos financieros como claves o
                  usuarios mediante correo electrónico. Si tienes alguna pregunta nos puedes contactar a través de tu App en
                  la sección Ayuda o consulta en <a href="https://www.helpy.com.co">www.helpy.com.co</a>
              </h2>
            </div>
            <br><br>
        </td>
    </tr>
    `;
    return txtContent;
  }

  async txtMailCodigoOTP(data) {
    let txtContent = await `
      <td bgcolor="#f4f4f4" align="center" style="padding: 0px 10px 0px 10px;">
        <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px;">
          <tr>
            <td bgcolor="#ffffff" align="left" style="padding: 20px 30px 40px 30px; color: #666666; font-family: 'Lato', Helvetica, Arial, sans-serif; font-size: 18px; font-weight: 400; line-height: 25px;">
              <p style="margin: 0;">
                Este es el código de seguridad para validar tu número celular, en la solicitud de Helpy Bank
              </p>
            </td>
          </tr>
          <tr>
            <td bgcolor="#ffffff" align="left">
              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tr>
                  <td bgcolor="#ffffff" align="center" style="padding: 20px 30px 60px 30px;">
                    <table border="0" cellspacing="0" cellpadding="0">
                      <tr>
                        <td align="center" style="border-radius: 3px;" bgcolor="#fed149">
                          <div style="font-size: 22px; font-weight: bold; font-family: Helvetica, Arial, sans-serif; color: #ffffff; text-decoration: none; padding: 15px 25px; border-radius: 2px; border: 1px solid #fed149; display: inline-block;">
                            ${data.otp}
                          </div>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td bgcolor="#ffffff" align="left" style="padding: 0px 30px 20px 30px; color: #666666; font-family: 'Lato', Helvetica, Arial, sans-serif; font-size: 18px; font-weight: 400; line-height: 25px;">
              <p style="margin: 0;">
                <br>En Helpy nunca te pediremos que compartas estos códigos con alguien más.
              </p>
            </td>
          </tr>
          <tr>
            <td bgcolor="#ffffff" align="left" style="padding: 0px 30px 40px 30px; border-radius: 0px 0px 4px 4px; color: #666666; font-family: 'Lato', Helvetica, Arial, sans-serif; font-size: 18px; font-weight: 400; line-height: 25px;">
              <p style="margin: 0;">Gracias,<br>Helpy Bank Team</p>
            </td>
          </tr>
        </table>
      </td>
    `;
    return txtContent;
  }

}
