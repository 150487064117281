import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DbrealtimeService {

  constructor(
    public db: AngularFireDatabase,
    public http: HttpClient,
  ) { }

  httpOptions_Cli = {
    headers: new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      'Authorization': 'Basic Mzg4NGEyMTYtMTk1Yi00YzFkLTgyNjktNzIxOWViMjNiOGQ4'
    })
  }

  httpOptions_Cloud = {
    headers: new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      'Authorization': 'Basic NjMxOGQ4MWYtYTBlOS00MDJkLTg0ZTEtMmUyY2E1MWM2MTEy'
    })
  }

  httpOptions_Correo = {
    headers: new HttpHeaders({
      "Access-Control-Allow-Origin": "*",
    })
  }



  async enviarPushOSignal(data) {

    let url = environment.api_push_onesignal;

    if (data.tipo === '1_cliente') {

      const post_data = {
        'app_id': '09e3ee19-9332-49f7-8d61-4a5900beeff1',
        'contents': {
          'en': data.msjPush
        },
        'headings': {
          'en': data.titulo
        },
        'big_picture': data.img,
        'ios_attachments': data.img,
        'android_channel_id': '67999849-a576-4a72-8307-7a337a02a931',
        'ios_sound': 'helpy_push.wav',
        'include_external_user_ids': [data.destino],
      }

      this.http.post(url, post_data, this.httpOptions_Cli)
        .subscribe(new_data => {
          console.log(new_data)
        }, error => {
          console.log(error);
        });

    }


    if (data.tipo === '1_empresa') {

      const post_data = {
        'app_id': 'c125f249-cb59-4fdc-a8c6-1e5ca5c48255',
        'contents': {
          'en': data.msjPush
        },
        'headings': {
          'en': data.titulo
        },
        'big_picture': data.img,
        'ios_attachments': data.img,
        'android_channel_id': '8aa12e6e-0132-43c6-82f8-aefacc8ab490',
        'ios_sound': 'helpy_push.wav',
        'include_external_user_ids': [data.destino],
      }

      this.http.post(url, post_data, this.httpOptions_Cloud)
        .subscribe(new_data => {
          console.log(new_data)
        }, error => {
          console.log(error);
        });

    }



    if (data.tipo === 'admin') {

      const post_data = {
        'app_id': '09e3ee19-9332-49f7-8d61-4a5900beeff1',
        'contents': {
          'en': data.msjPush
        },
        'headings': {
          'en': data.titulo
        },
        'big_picture': data.img,
        'ios_attachments': data.img,
        'android_channel_id': '67999849-a576-4a72-8307-7a337a02a931',
        'ios_sound': 'helpy_push.wav',
        filters: [
          { "field": "tag", "key": 'usuario', "relation": "=", "value": "admin" }
        ]

      }

      this.http.post(url, post_data, this.httpOptions_Cli)
        .subscribe(new_data => {
          console.log(new_data)
        }, error => {
          console.log(error);
        });

    }


  }


  traeTokenPushEmpresa(nit) {
    return this.db.object('empresas/' + nit + '/tokenpush').valueChanges();
  }

  traeUserAdmin() {
    return this.db.list('usuarios2/', ref => ref.orderByChild('rol').equalTo('admin'));
  }

  traeTokenPushEmp(nit) {
    return this.db.object('empresas/' + nit + '/tokenpush').valueChanges();
  }

  traeTokenPushUser(id_user) {
    return this.db.object('usuarios2/' + id_user + '/tokenpush').valueChanges();
  }

  pasarelaPago(dataPago) {
    this.http.post('https://helpy.com.co/pasarela/boton.php', dataPago, this.httpOptions_Correo)
      .subscribe(new_data => {
        console.log(new_data)
      }, error => {
        console.log(error);
      });
  }


  guardaDispositivo(data, nit, plataforma, fecha) {
    this.db.database.ref('empresas/' + nit + '/_dispositivo/' + plataforma + '/').update(data);
    this.db.database.ref('empresas/' + nit + '/_dispositivo/' + plataforma + '/').update({ fecha: fecha });
  }

  conseActConve() {
    return this.db.object('/consecutivo_convenio/actual').valueChanges();
  }

  conseActualizarConve(conseActual) {
    this.db.database.ref('consecutivo_convenio/').update({ actual: conseActual });
  }

  traeLikesEmp(nit) {
    return this.db.object('/empresas/' + nit + '/_esta_megus').valueChanges();
  }

  traeEstadoEmpresa(nit) {
    return this.db.object('/empresas/' + nit + '/emp_estado').valueChanges();
  }

  traeDirPPalEmpresa(nit) {
    return this.db.object('/empresas/' + nit + '/emp_dir_ppal').valueChanges();
  }

  traeMailEmpr(nit) {
    return this.db.object('empresas/' + nit + '/emp_correo/').valueChanges();
  }

  traeDocRepLegal(nit) {
    return this.db.object('empresas/' + nit + '/repre_num_doc/').valueChanges();
  }

  traeNomEmpr(nit) {
    return this.db.object('empresas/' + nit + '/emp_nom/').valueChanges();
  }

  cambiaPass(data) {
    return this.db.database.ref('empresas/' + data.nit + '/login').update({ pass: data.pass });
  }

  traefechafinPlan(nit) {
    return this.db.object('empresas/' + nit + '/plan_fecha_fin/').valueChanges();
  }

  traeVideosCloud() {
    return this.db.object('/videos_cloud/').valueChanges();
  }

  traeCategoria() {
    return this.db.list('categorias_/').valueChanges();
  }

  trae1Empresa(idnit) {
    return this.db.object('empresas/' + idnit).valueChanges();
  }

  actuEmp(emp) {
    return this.db.database.ref('empresas/' + emp.emp_nit).update(emp);
  }

  traePlanEmp(plan) {
    return this.db.object('planes/' + plan).valueChanges();
  }

  traeNomEmpresa2(nit) {
    return this.db.object('/empresas/' + nit + '/emp_nom').valueChanges();
  }

  traeValorBono() {
    return this.db.object('bonos_precio/precio').valueChanges();
  }

  traeBonosRecibe(idemp) {
    return this.db.list('_bonos_reg_uso/', ref => ref.orderByChild('uso_en_nit').equalTo(idemp));
  }

  traeBonosDeEmp(idemp) {
    return this.db.list('_bonos2/', ref => ref.orderByChild('sol_id').equalTo(idemp));
  }

  conseActBonos() {
    return this.db.object('/consecutivo_bonos/actual').valueChanges();
  }

  crearBonoMasivos(data) {
    return this.db.database.ref('_bonos2/' + data.id_bono).set(data);
  }

  traeBonosPorEstado(estado_cod) {
    return this.db.list('_bonos2/', ref => ref.orderByChild('estado_bono_codigo').equalTo(estado_cod));
  }

  conseActualizarBonos(conseActual) {
    this.db.database.ref('consecutivo_bonos/').update({ actual: conseActual });
  }

  buscarCodigo(codigotext) {
    return this.db.list('_bonos2/', ref => ref.orderByChild('filtro_codigo').equalTo(codigotext));
  }

  traeBono(id) {
    return this.db.object('_bonos2/' + id).valueChanges();
  }

  actuBono(data) {
    return this.db.database.ref('_bonos2/' + data.id_bono).update(data);
  }

  actuBonoUsados(data) {
    return this.db.database.ref('_bonos2/' + data.id_bono + '/bono_users_usados/' + data.id).update(data);
  }

  creaRegBono(data) {
    return this.db.database.ref('_bonos_reg_uso/' + data.id).set(data);
  }

  traeImgEmp(nit) {
    return this.db.object('empresas/' + nit + '/emp_img').valueChanges();
  }


  buscaNit(nit) {
    return this.db.object('empresas/' + nit).valueChanges();
  }

  traeCatEmpresa(nit) {
    return this.db.object('empresas/' + nit + '/tagppal').valueChanges();
  }

  promosHoyEmp(nit) {
    return this.db.list('promos_hoy/', ref => ref.orderByChild('nit').equalTo(nit));
  }

  actu1raVezCantPS(nit, cant) {
    return this.db.database.ref('empresas/' + nit).update({ plan_ps: cant });
  }

  traeCantOferHoy(nit) {
    return this.db.object('empresas/' + nit + '/plan_oferHoy').valueChanges();
  }

  traePromoMesEmp(nit) {
    return this.db.list('promos_mes/', ref => ref.orderByChild('nit').equalTo(nit));
  }

  traeCantOfer(nit) {
    return this.db.object('empresas/' + nit + '/plan_ofer').valueChanges();
  }

  traeCantPS(nit) {
    return this.db.object('empresas/' + nit + '/plan_ps').valueChanges();
  }

  elimPromo(data) {
    return this.db.database.ref('empresas/' + data.nit + '/promos/' + data.id).remove();
  }

  crearPromoMes(data) {
    return this.db.database.ref('promos_mes/' + data.id).set(data);
  }

  crearPromoHoy(oferHoy) {
    return this.db.database.ref('promos_hoy/' + oferHoy.id).set(oferHoy);
  }

  actuPromoMes(data) {
    return this.db.database.ref('promos_mes/' + data.id).update(data);
  }

  actuPromoHoy(data) {
    return this.db.database.ref('promos_hoy/' + data.id).update(data);
  }

  elimPromoMes(id) {
    return this.db.database.ref('promos_mes/' + id).remove();
  }

  elimPromoHoy(id) {
    return this.db.database.ref('promos_hoy/' + id).remove();
  }

  traePSEmp(nit) {
    return this.db.list('product_serv/', ref => ref.orderByChild('nit').equalTo(nit));
  }

  creaPSEmp(ps) {
    return this.db.database.ref('product_serv/' + ps.id).set(ps);
  }

  actuPSEmp(ps) {
    return this.db.database.ref('product_serv/' + ps.id).update(ps);
  }

  elimPSEmp(id) {
    return this.db.database.ref('product_serv/' + id).remove();
  }

  creaEmpresa(empresa) {
    return this.db.database.ref('empresas/' + empresa.emp_nit).set(empresa);
  }



  traeNomEmp(nit) {
    return this.db.object('empresas/' + nit + '/emp_nom').valueChanges();
  }

  traeFaceEmp(nit) {
    return this.db.object('empresas/' + nit + '/rs_face').valueChanges();
  }

  traeInstaEmp(nit) {
    return this.db.object('empresas/' + nit + '/rs_insta').valueChanges();
  }

  traeWhatEmp(nit) {
    return this.db.object('empresas/' + nit + '/rs_what').valueChanges();
  }

  traeGpsEmp_lat(nit) {
    return this.db.object('empresas/' + nit + '/emp_dir_ppal_lat').valueChanges();
  }

  traeGpsEmp_lon(nit) {
    return this.db.object('empresas/' + nit + '/emp_dir_ppal_lon').valueChanges();
  }

  traeCorrEmp(nit) {
    return this.db.object('empresas/' + nit + '/emp_correo').valueChanges();
  }

  traePWebEmp(nit) {
    return this.db.object('empresas/' + nit + '/emp_pagina_web').valueChanges();
  }

  traeTelEmp(nit) {
    return this.db.object('empresas/' + nit + '/emp_tel').valueChanges();
  }

  traeImg2() {
    return this.db.object('_publicidad/2/img').valueChanges();
  }

  traeImg3() {
    return this.db.object('_publicidad/3/img').valueChanges();
  }

  traeImg5() {
    return this.db.object('_publicidad/5/img').valueChanges();
  }

  traeImg6() {
    return this.db.object('_publicidad/6/img').valueChanges();
  }

  mostrariOS_btn_elim_cta() {
    return this.db.object('mostrar_ios/cloud_btn_alim_cta').valueChanges();
  }

  mostrariOS_imagenes_home() {
    return this.db.object('mostrar_ios/cloud_imagenes_home').valueChanges();
  }

  traeValorRefer() {
    return this.db.object('valor_refer/valor_refer').valueChanges();
  }

  creaInfoPuntos(data) {
    return this.db.database.ref('_puntos/' + data.id).set(data);
  }

  buscaCodigoRefer(cod) {
    return this.db.list('usuarios2/', ref => ref.orderByChild('ntarg').equalTo(cod));
  }

  buscaCodigoReferEmp(cod) {
    return this.db.list('empresas/', ref => ref.orderByChild('plan_conv_id').equalTo(cod));
  }

  traeDispositivo(id_2) {
    return this.db.object('usuarios2/' + id_2 + '/_dispositivo').valueChanges();
  }

  traeDocUser(id) {
    return this.db.object('usuarios2/' + id + '/doc').valueChanges();
  }

  traeDispositivoEmp(nit) {
    return this.db.object('empresas/' + nit + '/_dispositivo').valueChanges();
  }

  traeCodigoReferEmp(nit) {
    return this.db.object('empresas/' + nit + '/plan_conv_id').valueChanges();
  }

  guardaReferidoOKEmp(dataPuntos, actUser) {
    this.db.database.ref('_puntos/' + dataPuntos.id).set(dataPuntos);
    return this.db.database.ref('empresas/' + actUser.refer_user_quien_refirio).update(actUser);
  }

  traeUsuParaTarjeta(id) {
    return this.db.object('usuarios2/' + id).valueChanges();
  }

  traeEmpresaNit(nit) {
    return this.db.list('empresas/', ref => ref.orderByChild('emp_nit').equalTo(nit));
  }

  cambiarEstadoEmp(data) {
    return this.db.database.ref('empresas/' + data.emp_nit).update({ emp_estado: data.emp_estado });
  }

  traeUserApi() {
    return this.db.object('apis_user/0').valueChanges();
  }
}
