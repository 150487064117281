import { Component, Injectable, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { Platform, AlertController, ModalController, IonModal, LoadingController, MenuController, ToastController } from '@ionic/angular';
import { NavigationExtras, Router } from '@angular/router';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { LocalstorageService } from './services/localstorage.service';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { Browser } from '@capacitor/browser';
import { DbrealtimeService } from './services/dbrealtime.service';
import { SmsService } from './services/sms.service';
import { MailsService } from './services/mails.service';
import { AccesoLoginPage } from './components/acceso-login/acceso-login.page';
import { AccesoregistroPage } from './components/accesoregistro/accesoregistro.page';
import { SesionuserService } from './services/sesionuser.service';
import { Observable } from 'rxjs';
import { MiqrPage } from './components/miqr/miqr.page';
import { Share } from '@capacitor/share';
import { AccesorestringidoPage } from './components/accesorestringido/accesorestringido.page';

import OneSignal from 'onesignal-cordova-plugin';
import { PushNotifications } from '@capacitor/push-notifications';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
@Injectable()

export class AppComponent {

  private identificadoEmp$: Observable<boolean>;

  @ViewChild(IonModal) modalLogin: IonModal;
  @ViewChild(IonModal) modalRecPass: IonModal;
  @ViewChild(IonModal) modalRegisterUser: IonModal;

  AppVer: any = null;
  year: any = null;
  msj: string;
  data: any = [];
  dataPush: any = [];
  tokenUser: string = null;
  isMobilePlatform: boolean = false;

  modalOpenRecPass: Boolean = false

  msjUser: string;
  userNom: any = null
  valCorreo: boolean = false
  valCel: boolean = false
  validaMedioEnv: boolean;
  aster1: string;
  aster2: string;
  correoMostrar: string;
  celMostrar: string;
  medio: any = [];
  userMail: any;
  userCel: string;
  userId2: any;
  registro: any = [];
  lat: any;
  lng: any;
  numTarAct: any = null;
  empIdentificado: boolean = false
  ultIsesion: any = null;
  nomEmp: any;
  empImagen: any = null;

  constructor(
    private platform: Platform,
    public router: Router,
    public appVersion: AppVersion,
    public plt: Platform,
    public storage: LocalstorageService,
    public alertCtrl: AlertController,
    public modalCtrl: ModalController,
    public loadingCtrl: LoadingController,
    public menuCtrl: MenuController,
    public toastCtrl: ToastController,
    public call: CallNumber,
    public fbDB: DbrealtimeService,
    public sendSms: SmsService,
    public sendMail: MailsService,
    public sesionUser: SesionuserService,
  ) {

    this.initializeApp();

    this.sesionUser.setUserIdentificado();

    this.identificadoEmp$ = this.sesionUser.getEmpIdentificado;
    this.identificadoEmp$.subscribe(empIdent => {
      if (empIdent) {
        this.storage.get('uhcloud_eidentificado').then((empI) => {
          this.empIdentificado = (empI.value === 'true'); //forma de poner un string true en booelan true y lo mismo con false

          if (this.empIdentificado) {
            this.storage.get('uhcloud_empN').then((res) => {
              if (res.value !== null) {
                this.nomEmp = res.value;
              }
            })
          }

          this.storage.get('uhcloud_imagen').then((res) => {
            if (res.value !== null) {
              this.empImagen = res.value;
            }
            else {
              this.empImagen = 'no'
            }
          })

          this.storage.get('uhcloud_ui').then((ulIn) => {
            if (ulIn.value !== null) {
              this.ultIsesion = ulIn.value;
            }
          })

        })
      }
      else {
        this.empIdentificado = false;
      }

    })

    //Valido sesion de usuario directo a localstorage
    this.storage.get('uhcloud_eidentificado').then(async (empIdentificado: any) => {

      if (empIdentificado) {
        this.storage.get('uhcloud_ui').then(async (ultimoIng: any) => {
          this.ultIsesion = ultimoIng.value ? ultimoIng.value : null;
        })
        let ultimoIngreso = moment().format("x");
        this.storage.set('uhcloud_ui', ultimoIngreso)
        this.sesionUser.setEmpUI(ultimoIngreso);

        this.storage.get('uhcloud_empN').then(async (empNom: any) => {
          this.storage.get('uhcloud_ui').then(async (empUi: any) => {
            this.nomEmp = empNom.value;
            this.ultIsesion = empUi.value;
          })
        })
      }
    })

  }

  initializeApp() {
    this.platform.ready().then(() => {
      // this.splashScreen.hide();
      this.cargaDataApp();
      if (this.plt.is("mobile")) {
        this.oneSignalInit();
      }
    });
  }

  async oneSignalInit() {
    OneSignal.setAppId("c125f249-cb59-4fdc-a8c6-1e5ca5c48255");
    OneSignal.setNotificationOpenedHandler(async (jsonData) => {
      let push: any = jsonData.notification;

      if (push.bigPicture) {
        let alert = await this.alertCtrl.create({
          cssClass: 'alertasNotipush',
          mode: 'ios',
          header: push.title,
          subHeader: push.body,
          message: '<img src=' + push.bigPicture + '>',
          buttons: ['Cerrar']
        });
        await alert.present();
      }
      else {
        let alert2 = await this.alertCtrl.create({
          cssClass: 'alertasNotipushSinImg',
          mode: 'ios',
          header: push.title,
          subHeader: push.body,
          buttons: ['Cerrar']
        });
        await alert2.present();
      }
    });

    OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
      console.log("User accepted notifications: " + accepted);
    });

    //Afilia a un tag
    window['plugins'].OneSignal.sendTag("usuario", 'empresas');

    await PushNotifications.addListener('pushNotificationReceived', async (notification: any) => {
      console.log('Push notification received: ', notification);
    });


    // // Set your iOS Settings
    // var iosSettings = {};
    // iosSettings["kOSSettingsKeyAutoPrompt"] = false;
    // iosSettings["kOSSettingsKeyInAppLaunchURL"] = false;

    // window["plugins"].OneSignal
    //   .setAppId("09e3ee19-9332-49f7-8d61-4a5900beeff1")
    //   .handleNotificationOpened(notificationOpenedCallback)
    //   .iOSSettings(iosSettings)
    //   .inFocusDisplaying(window["plugins"].OneSignal.OSInFocusDisplayOption.Notification)
    //   .endInit();
  }


  async cargaDataApp() {

    moment.locale('es');

    this.year = moment().format('YYYY');

    if (this.isMobilePlatform) {
      this.appVersion.getVersionNumber().then(value => {
        this.AppVer = value;
      });
    }

  }


  async iniSesion() {
    const modal = await this.modalCtrl.create({
      component: AccesoLoginPage,
      mode: 'ios',
      initialBreakpoint: 0.80,
    });
    return await modal.present();
  }

  async registrarseUser() {
    this.menuCtrl.close();
    const modal = await this.modalCtrl.create({
      component: AccesoregistroPage,
      mode: 'ios',
      initialBreakpoint: 0.95
    });
    return await modal.present();
  }


  irMiTarjeta() {
    this.router.navigate(['mitarjeta']);
  }


  inicio() {
    this.router.navigate(['home']);
  }

  ofermes() {
    if (this.empIdentificado) {
      this.router.navigate(['ofermes'], { replaceUrl: true });
    }
    else {
      this.noIdentificadoEmp();
    }
  }

  oferhoy() {
    if (this.empIdentificado) {
      this.router.navigate(['oferhoy'], { replaceUrl: true });
    }
    else {
      this.noIdentificadoEmp();
    }
  }

  productserv() {
    if (this.empIdentificado) {
      this.router.navigate(['productserv'], { replaceUrl: true });
    }
    else {
      this.noIdentificadoEmp();
    }
  }


  miperfil() {
    this.menuCtrl.close();
    if (this.empIdentificado) {
      this.router.navigate(['misdatos'], { replaceUrl: true });
    }
    else {
      this.noIdentificadoEmp();
    }
  }

  bonos() {
    if (this.empIdentificado) {
      this.router.navigate(['bonos'], { replaceUrl: true });
    }
    else {
      this.noIdentificadoEmp();
    }
  }

  async codigoQr() {

    if (this.empIdentificado) {

      const alert = await this.alertCtrl.create({
        cssClass: 'alertas',
        mode: 'ios',
        header: 'Que acción desea realizar',
        buttons: [
          {
            text: 'Mostrar mi QR',
            handler: async () => {
              const modal = await this.modalCtrl.create({
                component: MiqrPage,
                initialBreakpoint: 0.65,
                showBackdrop: true,
                mode: 'ios',
              });
              return await modal.present();
            }
          }, {
            text: 'Cobrar con QR',
            handler: async () => {

              let navigationExtras: NavigationExtras = {
                queryParams: {
                  mostrar: 'cobrarClientes',
                }
              };
              this.router.navigate(['referidos'], navigationExtras);

            }
          }
        ]
      });
      await alert.present();

    }
    else {
      this.noIdentificadoEmp();
    }

  }

  async compartir() {

    const alert = await this.alertCtrl.create({
      cssClass: 'alertas',
      mode: 'ios',
      message: 'Deseas compartir App Helpy con:',
      buttons: [
        {
          text: 'Clientes',
          handler: async () => {
            let shareRet = await Share.share({
              title: 'Te invito a que descargues la App Helpy!!!',
              text: 'Hola 👋 descarga la app de Helpy📲 en donde podras encontrar todos nuestros productos y servicios, ademas tienes muchos beneficios por tan solo descargarla e inscribirte, es totalmente gratis!!!...  ⬇️ Aquí te dejo el link ⬇️ ',
              url: 'https://bit.ly/Descarga_Helpy',
              dialogTitle: 'Compartir Helpy en:'
            });
          }
        }, {
          text: 'Otra empresa',
          handler: async () => {
            let shareRet = await Share.share({
              title: 'Te invito a que descargues la App Helpy!!!',
              text: 'Hola 👋 descarga la nueva app de Helpy para empresas📲 en ella podras publicar de forma gratuita tus productos y servicios y asi incrementar tus ventas y ser mas visible para nuevos clientes...  ⬇️ Aquí te dejo el link ⬇️ ',
              url: 'https://bit.ly/Descarga_Helpy',
              dialogTitle: 'Compartir Helpy en:'
            });
          }
        }
      ]
    });
    await alert.present();

  }


  tyc() {
    this.router.navigate(['tyc']);
  }


  ayuda() {
    this.router.navigate(['ayuda']);
  }


  async chat() {

    this.modalOpenRecPass = false;

    const alert = await this.alertCtrl.create({
      cssClass: 'alertas',
      mode: 'ios',
      message: '<b>Tienes Dudas, Sugerencias, Opiniones o alguna información que nos quieras suminsitrar?</b><br><br> Presiona una de las siguientes opciones para comunicarte con nosotros:',
      buttons: [
        {
          text: 'Llamada Telefonica',
          handler: async () => {

            if (this.isMobilePlatform) {

              this.call.callNumber("3148747770", true)
                .then(res => console.log('Launched dialer!', res))
                .catch(err => console.log('Error launching dialer', err));
            }
            else {

              const alert = await this.alertCtrl.create({
                cssClass: 'alertas',
                mode: 'ios',
                message: 'Nuestra linea directa de atencion al publico: <b>3148747770</b>',
                buttons: ['Aceptar']
              });

              await alert.present();

            }

          }
        }, {
          text: 'Whatsapp',
          handler: async () => {

            this.msj = "https://wa.me/573148747770?text=Hola,%20requiero%20ayuda%20sobre%20un%20asunto%20de%20HELPY!";
            await Browser.open({ url: this.msj });

          }
        }
      ]
    });
    await alert.present();

  }


  async salir() {

    const alert = await this.alertCtrl.create({
      cssClass: 'alertas',
      mode: 'ios',
      message: '<b>Esta seguro que <br>deseas cerrar sesión?</b>',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          handler: async () => {
          }
        }, {
          text: 'Si',
          handler: async () => {
            this.menuCtrl.close();
            this.storage.clear();
            this.sesionUser.setUserIdentificado();
            this.storage.set('uhc_uidentificado', 'false');

            const toast = await this.toastCtrl.create({
              message: 'Has cerrado sesión!!!',
              duration: 3000,
              position: 'top',
              mode: 'ios'
            });
            await toast.present();
          }
        }
      ]
    });
    await alert.present();

  }


  irTC() {
    this.modalLogin.dismiss();
    this.router.navigate(['tyclogin']);
  }



  async noIdentificadoEmp() {
    const modal = await this.modalCtrl.create({
      component: AccesorestringidoPage,
      mode: 'ios',
      initialBreakpoint: 0.70,
    });
    return await modal.present();
  }

}
