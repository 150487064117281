import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { environment } from 'src/environments/environment.prod';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MailsService {

  private apiUrl = environment.urlNotificationMail;

  constructor(
    public http: HttpClient,
    public db: AngularFireDatabase
  ) { }

  async initSendEmail(data) {
    const username = environment.projectMail_user;
    const password = environment.projectMail_pass;

    this.login(username, password).subscribe(response => {
      const token = response.token;
      this.sendEmail(token, data.title, data.body, data.recipients, data.template, environment.projectMailName, data.attachments).subscribe({
        next: async (res) => {
          console.log('Email sent successfully', res);
        },
        error: async (err) => {
          console.error('Error sending email', err);
        }
      });
    });
  }

  login(username: string, password: string): Observable<any> {
    const url = `${this.apiUrl}/auth/login`;
    return this.http.post(url, { username, password });
  }

  sendEmail(
    token: string,
    title: string,
    body: string,
    recipients: string,
    template: string,
    project: string,
    attachments: { base64File: string, filename: string, mimetype: string }[] = []
  ): Observable<any> {
    const url = `${this.apiUrl}/email/send`;
    const headers = { 'Authorization': `Bearer ${token}` };

    // Crear FormData para enviar los archivos como buffer
    const formData = new FormData();
    formData.append('title', title);
    formData.append('body', body);
    formData.append('recipients', recipients);
    formData.append('template', template);
    formData.append('project', project);

    attachments.forEach((attachment, index) => {
      const byteCharacters = atob(attachment.base64File);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: attachment.mimetype });
      formData.append('attachments', blob, attachment.filename);
    });

    return this.http.post(url, formData, { headers });
  }
  
}

