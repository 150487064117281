import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { LocalstorageService } from './localstorage.service';


@Injectable({
  providedIn: 'root'
})
export class SesionuserService {

  private empNom = new BehaviorSubject<boolean>(false);
  private empUI = new BehaviorSubject<boolean>(false);
  private empImg = new BehaviorSubject<boolean>(false);
  private empIdentificado = new BehaviorSubject<boolean>(false);

  constructor(
    private storage: LocalstorageService
  ) { }

  get getEmpNom() {
    return this.empNom.asObservable();
  }

  get getEmpUI() {
    return this.empUI.asObservable();
  }

  get getEmpIdentificado() {
    return this.empIdentificado.asObservable();
  }

  get getEmpImg() {
    return this.empImg.asObservable();
  }

  public setEmpNom(nom) {
    this.storage.set('uhcloud_empN', nom);
    this.empNom.next(true);
  }

  public setEmpUI(ui) {
    this.storage.set('uhcloud_ui', ui);
    this.empUI.next(true);
  }

  public setEmpImg(ui) {
    this.storage.set('uhcloud_imagen', ui);
    this.empImg.next(true);
  }

  public setUserIdentificado() {
    let hoy = moment().format('x');
    this.storage.get('uhcloud_lab').then(async (loginAB: any) => {
      if (loginAB.value) {
        let estadoEmpIdentificado = (parseInt(hoy) > parseInt(loginAB.value)) ? false : true;
        if (!estadoEmpIdentificado) {
          this.storage.clear();
        }
        else {
          this.storage.set('uhc_eidentificado', String(estadoEmpIdentificado));
        }
      }
      else {
        this.storage.clear();
        this.storage.set('uhc_eidentificado', 'false');
      }
      this.empIdentificado.next(true);
    })
  }

}