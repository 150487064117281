import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { DbrealtimeService } from './dbrealtime.service';
import { environment } from 'src/environments/environment.prod';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class SmsService {

  respuesta: any;
  status: string;
  arrayCel: any = [];
  arrayCelFinal: any = [];

  constructor(
    public http: HttpClient,
    public db: AngularFireDatabase,
    public database: DbrealtimeService
  ) { }


  normalizeAndRemoveDiacritics(text) {
    return text.normalize('NFD')
      .replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1")
      .normalize();
  }

  removeEmojis(text) {
    return text.replace(/([#0-9]\u20E3)|[\xA9\xAE\u203C\u2047-\u2049\u2122\u2139\u3030\u303D\u3297\u3299][\uFE00-\uFEFF]?|[\u2190-\u21FF][\uFE00-\uFEFF]?|[\u2300-\u23FF][\uFE00-\uFEFF]?|[\u2460-\u24FF][\uFE00-\uFEFF]?|[\u25A0-\u25FF][\uFE00-\uFEFF]?|[\u2600-\u27BF][\uFE00-\uFEFF]?|[\u2900-\u297F][\uFE00-\uFEFF]?|[\u2B00-\u2BF0][\uFE00-\uFEFF]?|(?:\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDEFF])[\uFE00-\uFEFF]?/g, '');
  }

  removeSpecificCharacters(text) {
    return text.replace(/&#/g, "").replace(/#/g, "");
  }

  removeExtraSpaces(text) {
    return text.replace(/ {2,}/g, " ");
  }

  encodeUriAndReplacePlus(text) {
    return encodeURI(text).replace(/[+]/gi, '%2B');
  }

  async initSendSms(data) {
    let phoneNumber = data.phoneNumber;
    let message = data.message;

    await this.database.traeUserApi().subscribe(resp => {
      let dataUser: any = resp;
      console.log(dataUser);


      this.login(dataUser.user, dataUser.pass, environment.apiEnvironment).subscribe(
        token => {
          this.sendSms(token, phoneNumber, message).subscribe(
            response => {
              console.log('SMS sent successfully', response);
            },
            error => {
              console.error('Failed to send SMS', error);
            }
          );
        },
        error => {
          console.error('Login failed', error);
        }
      );

    });
  }

  login(username: string, password: string, env: string): Observable<any> {
    const body = { username, password, env };
    return this.http.post(`${environment.apiHelpyUrl}/helpy_fire/login`, body).pipe(
      map((response: any) => response.token)
    );
  }

  private sendSms(token: string, phoneNumber: string, message: string): Observable<any> {
    let result = message;
    result = this.normalizeAndRemoveDiacritics(result);
    result = this.removeEmojis(result);
    result = this.removeSpecificCharacters(result);
    result = this.removeExtraSpaces(result);
    result = this.encodeUriAndReplacePlus(result);

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    });

    let project = environment.projectSms;
    let apiEnvironment = environment.apiEnvironment;
    phoneNumber = `+57${phoneNumber}`;
    const body = { phoneNumber, message, project, apiEnvironment };

    let fechaYa = moment().format('x');
    let dataEnvSms = {
      id: Date.now(),
      fecha: moment(fechaYa, 'x').format('YYYY-MM-DD hh:mm a'),
      fecha_x: fechaYa,
      destino_cel: phoneNumber,
      msj: message,
      empresaenvia_nom: 'helpy',
    };
    this.guardaEnviosSms(dataEnvSms);

    return this.http.post(`${environment.apiHelpyUrl}/helpy_com_sms/send-sms`, body, { headers });
  }

  private guardaEnviosSms(data) {
    return this.db.database.ref('_sms/' + data.id).set(data);
  }

}
