import { Component, OnInit } from '@angular/core';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { Browser } from '@capacitor/browser';
import { AlertController, LoadingController, ModalController, Platform } from '@ionic/angular';
import * as moment from 'moment';
import { DbrealtimeService } from 'src/app/services/dbrealtime.service';
import { MailsService } from 'src/app/services/mails.service';
import { SmsService } from 'src/app/services/sms.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-acceso-recpass',
  templateUrl: './acceso-recpass.page.html',
  styleUrls: ['./acceso-recpass.page.scss'],
})
export class AccesoRecpassPage implements OnInit {

  viewDataIdentUser: Boolean = true
  viewDataIdentUserMsg: Boolean = false
  loginDUrecPass: any = []
  validaMedioEnv: boolean;
  msjUser: string;
  userNom: any = null
  valCorreo: boolean = false
  valCel: boolean = false
  aster1: string;
  aster2: string;
  correoMostrar: string;
  celMostrar: string;
  medio: any = [];
  userMail: any;
  userId2: any;
  userCel: string;
  isMobilePlatform: boolean = false;
  msj: string;
  dataUserDB: any = [];
  dataMail: any = null;
  dataRepLegal: any = null;
  nomEmp: any;
  correo: any;
  empNit: any = null;
  dataEmpresa: any = [];
  dataFullEmperesa: any = [];
  dataCel: any;
  userNit: any;

  constructor(
    public alertCtrl: AlertController,
    public loadingCtrl: LoadingController,
    public modalCtrl: ModalController,
    public sendSms: SmsService,
    public sendMail: MailsService,
    public utils: UtilsService,
    public fbDB: DbrealtimeService,
    public plt: Platform,
    public call: CallNumber
  ) { }

  ngOnInit() {
    //Valido que platform es
    this.isMobilePlatform = ((this.plt.is("desktop")) || (this.plt.is("mobileweb"))) ? false : true;
  }


  async recPassProccess(data) {
    this.aster1 = '';
    this.aster2 = '';

    let nitnum = data.nit;
    let docnum = data.doc;

    if ((!nitnum) || (!docnum)) {

      const alert2 = await this.alertCtrl.create({
        cssClass: 'alertas',
        mode: 'ios',
        message: 'Todos los datos son obligatorios. Por Favor Verifique',
        buttons: ['Aceptar']
      });
      await alert2.present();

    }
    else {

      const loading = await this.loadingCtrl.create({
        spinner: 'lines-sharp',
        mode: 'ios',
        backdropDismiss: false,
        cssClass: "loaderFull",
        message: 'Por favor espere'
      });
      loading.present();

      nitnum = String(data.nit);
      docnum = String(data.doc);

      let dataEmpLogin = this.fbDB.traeEmpresaNit(nitnum).valueChanges().subscribe(async resp => {
        this.dataEmpresa = resp;

        dataEmpLogin.unsubscribe();

        if (this.dataEmpresa.length === 0) {

          loading.dismiss();
          const alert2 = await this.alertCtrl.create({
            cssClass: 'alertas',
            mode: 'ios',
            message: 'No encontramos ninguna empresa relacionada con el Nit suministrado, por favor verifique',
            buttons: ['Aceptar']
          });
          await alert2.present();

        }
        else {

          this.dataFullEmperesa = this.dataEmpresa[0];

          if (String(this.dataFullEmperesa['repre_num_doc']) !== String(docnum)) {

            loading.dismiss();
            const alert2 = await this.alertCtrl.create({
              cssClass: 'alertas',
              mode: 'ios',
              message: 'información suministrada es incorrecta, Por favor verifique',
              buttons: ['Aceptar']
            });
            await alert2.present();
          }
          else {

            this.viewDataIdentUser = false;
            this.viewDataIdentUserMsg = true;

            this.userNom = this.dataFullEmperesa.repre_nom ? this.dataFullEmperesa.repre_nom : '';
            this.userId2 = this.dataFullEmperesa.plan_conv_id;
            this.userNit = this.dataFullEmperesa.emp_nit;
            this.userMail = this.dataFullEmperesa.repre_correo ? this.dataFullEmperesa.repre_correo : '';
            this.userCel = this.dataFullEmperesa.repre_cel ? String(this.dataFullEmperesa.repre_cel) : '';

            this.valCorreo = this.userMail.length > 5 ? true : false;
            this.valCel = this.userCel.length > 9 ? true : false;

            this.validaMedioEnv = (!this.valCorreo && !this.valCel) ? false : true;


            if (this.validaMedioEnv) {

              if (this.valCorreo) {

                this.aster1 = ''
                this.aster2 = ''
                let totalcadena = this.userMail.length;
                let posarroba = this.userMail.indexOf('@');

                let subs1 = this.userMail.substring(0, 2);
                let subs2 = this.userMail.substring(posarroba, (posarroba + 2));
                let subs3 = this.userMail.substring((totalcadena - 4), totalcadena);

                let hasta = posarroba - 3;
                for (let i = 0; i < hasta; i++) {
                  this.aster1 = this.aster1 + '*';
                }

                let hasta2 = (totalcadena - posarroba) - 4;
                for (let i = 0; i < hasta2; i++) {
                  this.aster2 = this.aster2 + '*';
                }

                this.correoMostrar = subs1 + this.aster1 + subs2 + this.aster2 + subs3;
              }

              if (this.valCel) {
                var primeros3 = this.userCel.substring(0, 3);

                var ultimos2 = this.userCel.slice(-2);
                this.celMostrar = primeros3 + ' XXX XX ' + ultimos2
              }

              loading.dismiss();

            }
            else {
              loading.dismiss();
              this.modalCtrl.dismiss();
              const alert = await this.alertCtrl.create({
                cssClass: 'alertas',
                mode: 'ios',
                backdropDismiss: false,
                message: 'Lastimosamente usted no posee información actualizada en la App, por lo cual no se le puede enviar instrucciones de recuperación de contraseña vía correo electrónico o mensaje de texto, si desea recuperar el acceso a su cuenta oprima la opción "Contacto vía` WhatsApp" y aun agente de servicio al cliente le ayudara a recuperar su cuenta.',
                buttons: [
                  {
                    text: 'Cerrar',
                    role: 'cancel',
                    handler: () => {

                    }
                  }, {
                    text: 'Conctato via Whatsapp',
                    handler: () => {
                      window.open("https://wa.me/573148747770?text=Hola,%20requiero%20ayuda%20con%20la%20recuperacion%20de%20mi%20contraseña,%20de%20Helpy%20Cloud%20Nit%20" + nitnum + ", Empresa: " + this.nomEmp, "_self");
                    }
                  }
                ]
              });
              await alert.present();
            }

          }

        }
      });
    }

  }


  async recuperarClave(data) {

    if (!data.correo && !data.cel) {
      let alert = await this.alertCtrl.create({
        cssClass: 'alertas',
        backdropDismiss: false,
        mode: 'ios',
        message: 'Debe seleccionar por lo menos un medio por el cual te enviaremos la clave temporal.',
        buttons: ['Cerrar']
      });
      await alert.present();
    }
    else {

      let clavenueva = this.generateRandomString(6);
      clavenueva = clavenueva.trim();
      this.nomEmp = this.dataFullEmperesa['emp_nom'];
      this.dataMail = this.dataFullEmperesa['repre_correo'] ? this.dataFullEmperesa['repre_correo'] : null;
      this.dataCel = this.dataFullEmperesa['repre_cel'] ? String(this.dataFullEmperesa['repre_cel']) : null;
      let msj = 'Helpy Cloud genero una clave temporal, para que inicies sesión, recuerda cambiarla desde la opción Mi Perfil: ' + clavenueva

      if (data.cel) {
        let destino = this.userCel;
        this.sendSms.initSendSms({ phoneNumber: destino, message: msj });
      }
      if (data.correo) {
        let txtMail = await this.utils.txtMailNewPass({ clave: clavenueva, user_nom: ``, year: moment().year() })
        let dataSendMailNewPass = {
          title: `Helpy Cloud, Contraseña restablecida`,
          body: txtMail,
          recipients: this.userMail,
          template: 'Helpy_general',
          attachments: []
        }
        await this.sendMail.initSendEmail(dataSendMailNewPass);


      }

      this.modalCtrl.dismiss();

      let datapass = { nit: this.dataFullEmperesa['emp_nit'], pass: clavenueva };
      this.fbDB.cambiaPass(datapass).then(async resp => {
        let alert = await this.alertCtrl.create({
          cssClass: 'alertas',
          backdropDismiss: false,
          mode: 'ios',
          message: 'Su nueva clave temporal fue enviada de manera exitosa, una vez iniciada sesión recuerde cambiar su contraseña desde la opción <b>Mi Perfil</b>',
          buttons: ['Cerrar']
        });
        await alert.present();
      })

    }

  }

  generateRandomString(num) {
    const characters = 'ABCDEFGHIJKLMNPQRSTUVWXYZabcdefghijklmnpqrstuvwxyz123456789';
    let result1 = ' ';
    const charactersLength = characters.length;
    for (let i = 0; i < num; i++) {
      result1 += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result1;
  }



  async chat() {

    const alert = await this.alertCtrl.create({
      cssClass: 'alertas',
      mode: 'ios',
      message: '<b>Tienes Dudas, Sugerencias, Opiniones o alguna información que nos quieras suminsitrar?</b><br><br> Presiona una de las siguientes opciones para comunicarte con nosotros:',
      buttons: [
        {
          text: 'Llamada Telefonica',
          handler: async () => {

            if (this.isMobilePlatform) {

              this.call.callNumber("3148747770", true)
                .then(res => console.log('Launched dialer!', res))
                .catch(err => console.log('Error launching dialer', err));

            }
            else {

              const alert = await this.alertCtrl.create({
                cssClass: 'alertas',
                mode: 'ios',
                message: 'Nuestra linea directa de atencion al publico: <b>3148747770</b>',
                buttons: ['Aceptar']
              });

              await alert.present();

            }

          }
        }, {
          text: 'Whatsapp',
          handler: async () => {

            this.msj = "https://wa.me/573148747770?text=Hola,%20requiero%20ayuda%20sobre%20un%20asunto%20de%20HELPY!";
            await Browser.open({ url: this.msj });

          }
        }
      ]
    });
    await alert.present();

  }


}
