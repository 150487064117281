import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'bonousuarios',
    loadChildren: () => import('./components/bonousuarios/bonousuarios.module').then( m => m.BonousuariosPageModule)
  },
  {
    path: 'menulat',
    loadChildren: () => import('./components/menulat/menulat.module').then( m => m.MenulatPageModule)
  },
  {
    path: 'miqr',
    loadChildren: () => import('./components/miqr/miqr.module').then( m => m.MiqrPageModule)
  },
  {
    path: 'queeshelpyx',
    loadChildren: () => import('./components/queeshelpyx/queeshelpyx.module').then( m => m.QueeshelpyxPageModule)
  },
  {
    path: 'ayuda',
    loadChildren: () => import('./pages/ayuda/ayuda.module').then( m => m.AyudaPageModule)
  },
  {
    path: 'bonos',
    loadChildren: () => import('./pages/bonos/bonos.module').then( m => m.BonosPageModule)
  },
  {
    path: 'bonosredimir',
    loadChildren: () => import('./pages/bonosredimir/bonosredimir.module').then( m => m.BonosredimirPageModule)
  },
  {
    path: 'editimage',
    loadChildren: () => import('./pages/editimage/editimage.module').then( m => m.EditimagePageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  // {
  //   path: 'login',
  //   loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  // },
  {
    path: 'miplan',
    loadChildren: () => import('./pages/miplan/miplan.module').then( m => m.MiplanPageModule)
  },
  {
    path: 'misdatos',
    loadChildren: () => import('./pages/misdatos/misdatos.module').then( m => m.MisdatosPageModule)
  },
  {
    path: 'misdatosdireccionesmodal',
    loadChildren: () => import('./pages/misdatosdireccionesmodal/misdatosdireccionesmodal.module').then( m => m.MisdatosdireccionesmodalPageModule)
  },
  {
    path: 'misdatoshorariosmodal',
    loadChildren: () => import('./pages/misdatoshorariosmodal/misdatoshorariosmodal.module').then( m => m.MisdatoshorariosmodalPageModule)
  },
  {
    path: 'oferhoy',
    loadChildren: () => import('./pages/oferhoy/oferhoy.module').then( m => m.OferhoyPageModule)
  },
  {
    path: 'oferhoyne',
    loadChildren: () => import('./pages/oferhoyne/oferhoyne.module').then( m => m.OferhoynePageModule)
  },
  {
    path: 'ofermes',
    loadChildren: () => import('./pages/ofermes/ofermes.module').then( m => m.OfermesPageModule)
  },
  {
    path: 'ofermesne',
    loadChildren: () => import('./pages/ofermesne/ofermesne.module').then( m => m.OfermesnePageModule)
  },
  {
    path: 'productserv',
    loadChildren: () => import('./pages/productserv/productserv.module').then( m => m.ProductservPageModule)
  },
  {
    path: 'productservne',
    loadChildren: () => import('./pages/productservne/productservne.module').then( m => m.ProductservnePageModule)
  },
  {
    path: 'referidos',
    loadChildren: () => import('./pages/referidos/referidos.module').then( m => m.ReferidosPageModule)
  },
  {
    path: 'registro',
    loadChildren: () => import('./pages/registro/registro.module').then( m => m.RegistroPageModule)
  },
  {
    path: 'tyc',
    loadChildren: () => import('./pages/tyc/tyc.module').then( m => m.TycPageModule)
  },
  {
    path: 'fechas',
    loadChildren: () => import('./components/fechas/fechas.module').then( m => m.FechasPageModule)
  },
  {
    path: 'acceso-login',
    loadChildren: () => import('./components/acceso-login/acceso-login.module').then( m => m.AccesoLoginPageModule)
  },
  {
    path: 'accesoregistro',
    loadChildren: () => import('./components/accesoregistro/accesoregistro.module').then( m => m.AccesoregistroPageModule)
  },
  {
    path: 'acceso-recpass',
    loadChildren: () => import('./components/acceso-recpass/acceso-recpass.module').then( m => m.AccesoRecpassPageModule)
  },
  {
    path: 'accesorestringido',
    loadChildren: () => import('./components/accesorestringido/accesorestringido.module').then( m => m.AccesorestringidoPageModule)
  },
  {
    path: 'cobrarhelpybank',
    loadChildren: () => import('./components/cobrarhelpybank/cobrarhelpybank.module').then( m => m.CobrarhelpybankPageModule)
  },
  {
    path: 'cobrarotphelpybank',
    loadChildren: () => import('./components/cobrarotphelpybank/cobrarotphelpybank.module').then( m => m.CobrarotphelpybankPageModule)
  },
  {
    path: 'ofernopubliexplica',
    loadChildren: () => import('./components/ofernopubliexplica/ofernopubliexplica.module').then( m => m.OfernopubliexplicaPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
