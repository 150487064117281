import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { AccesoLoginPage } from '../acceso-login/acceso-login.page';
import { AccesoregistroPage } from '../accesoregistro/accesoregistro.page';

@Component({
  selector: 'app-accesorestringido',
  templateUrl: './accesorestringido.page.html',
  styleUrls: ['./accesorestringido.page.scss'],
})
export class AccesorestringidoPage implements OnInit {

  modalOpenLogin: boolean

  constructor(
    public modalCtrl: ModalController,
    public router: Router
  ) { }

  ngOnInit() {
  }

  async home() {
    this.modalCtrl.dismiss();
    this.router.navigate(['home'], { replaceUrl: true });
  }

  async login() {
    this.modalCtrl.dismiss();
    const modal = await this.modalCtrl.create({
      component: AccesoLoginPage,
      mode: 'ios',
      initialBreakpoint: 0.80,
    });
    return await modal.present();
  }

  async registro() {
    this.modalCtrl.dismiss();
    const modal = await this.modalCtrl.create({
      component: AccesoregistroPage,
      mode: 'ios',
      initialBreakpoint: 0.95,
    });
    return await modal.present();

  }

  async onWillDismiss(){
    console.log('aca');
    this.modalCtrl.dismiss('descartado');
  }

}
