import { Component, OnInit } from '@angular/core';
import { LocalstorageService } from 'src/app/services/localstorage.service';
import { DbrealtimeService } from 'src/app/services/dbrealtime.service';
import { AccesorestringidoPage } from '../accesorestringido/accesorestringido.page';
import { Router } from '@angular/router';
import { LoadingController, ModalController } from '@ionic/angular';
import * as CryptoJS from 'crypto-js'
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-miqr',
  templateUrl: './miqr.page.html',
  styleUrls: ['./miqr.page.scss'],
})
export class MiqrPage implements OnInit {

  nit: any = null;
  dataEmp: any;
  nomEmp: any;
  createdCode: any;
  id_empresa: any;
  empIdentificado: boolean = false;

  constructor(
    public router: Router,
    public modalCtrl: ModalController,
    public loadingCtrl: LoadingController,
    public storage: LocalstorageService,
    public fbDb: DbrealtimeService
  ) { }

  ngOnInit() {

    this.storage.get('uhcloud_eidentificado').then(async (empI) => {
      this.empIdentificado = (empI.value === 'true'); //forma de poner un string true en booelan true y lo mismo con false

      if (this.empIdentificado) {

        this.storage.get('uhcloud_nit').then(async (val) => {
          this.nit = val.value;

          this.fbDb.trae1Empresa(this.nit).subscribe(data => {
            this.dataEmp = data;
            this.nomEmp = this.dataEmp.emp_nom;
            this.id_empresa = 'C' + this.dataEmp.plan_conv_id;

            let pre_createdCode = 'Empresa: ' + this.dataEmp.emp_nom + ' | Nit: ' + this.nit + ' | Direccion: ' + this.dataEmp.emp_dir_ppal + ' | Contactos: ' + this.dataEmp.emp_tel + ' ' + this.dataEmp.emp_cel;
            // this.createdCode = CryptoJS.AES.encrypt(pre_createdCode, environment.cifrado).toString();
            this.createdCode = pre_createdCode;
          });

        });

      }
      else {

        this.modalCtrl.dismiss();
        const modal = await this.modalCtrl.create({
          component: AccesorestringidoPage,
          mode: 'ios',
          initialBreakpoint: 0.95,
          breakpoints: [0, 0.95, 0.99]
        });
        return await modal.present();

      }

    })

  }

}