export const environment = {
  production: true,
  environmentFirebase: {
    apiKey: "AIzaSyANvCTLRnQ2ArRwszJp-41BAnbqdTGNbEs",
    authDomain: "famiseg-cfa30.firebaseapp.com",
    databaseURL: "https://famiseg-cfa30.firebaseio.com",
    projectId: "famiseg-cfa30",
    storageBucket: "famiseg-cfa30.appspot.com",
    messagingSenderId: "1064188871830",
    appId: "1:1064188871830:web:0d942082de6c75dcba2eb7",
    measurementId: "G-FVS4JH714Q"

    // apiKey: "AIzaSyDzMICpBgYuO5wb20fOEwC6-kyzEIzf-Yk",
    // authDomain: "helpy-test1.firebaseapp.com",
    // databaseURL: "https://helpy-test1-default-rtdb.firebaseio.com",
    // projectId: "helpy-test1",
    // storageBucket: "helpy-test1.appspot.com",
    // messagingSenderId: "747903901722",
    // appId: "1:747903901722:web:b2f3f527bdcc11126cf1a3",
    // measurementId: "G-TPH6ZEL7MN"
  },
  cifrado: 'H3lpy++',
  api_push_onesignal: 'https://onesignal.com/api/v1/notifications',

  apiHelpyUrl: 'https://apis.sds-soft.co:4175/v2', //sms y procesos internos - api propia de helpy
  urlNotificationMail: 'https://apis.sds-soft.co:4171/api', //correos, api general

  projectMailName: 'helpy',
  projectSms: 'helpy',
  projectMail_user: 'Sds77&*Hd',
  projectMail_pass: 'Jesio+Kerbsiz5',
  pesoMaximoArchivo: 3000000,

  apiEnvironment: 'prod', //dev
}
