import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, LoadingController, MenuController, ModalController, Platform } from '@ionic/angular';
import * as moment from 'moment';
import { DbrealtimeService } from 'src/app/services/dbrealtime.service';
import { LocalstorageService } from 'src/app/services/localstorage.service';
import { SesionuserService } from 'src/app/services/sesionuser.service';
import { AccesoRecpassPage } from '../acceso-recpass/acceso-recpass.page';
import { AccesoregistroPage } from '../accesoregistro/accesoregistro.page';
import { Device } from '@capacitor/device';

@Component({
  selector: 'app-acceso-login',
  templateUrl: './acceso-login.page.html',
  styleUrls: ['./acceso-login.page.scss'],
})
export class AccesoLoginPage implements OnInit {

  @ViewChild('passwordEyeRegister', { read: ElementRef }) passwordEye: ElementRef;
  passwordTypeInput = 'password';
  
  loginDU: any = [];
  viewDataIdentUser: Boolean = true
  viewDataIdentUserMsg: Boolean = false
  validaMedioEnv: boolean;
  userNom: any;
  userId2: any;
  userMail: any;
  userCel: string;
  valCorreo: boolean;
  valCel: boolean;
  aster1: string;
  aster2: string;
  correoMostrar: string;
  celMostrar: string;
  datalogin: any;
  dataEmp: any = null;
  dispositivo: any;
  estadoCuenta: string;

  constructor(
    public router: Router,
    public plt: Platform,
    public fbDB: DbrealtimeService,
    public storage: LocalstorageService,
    public sesionUser: SesionuserService,
    public alertCtrl: AlertController,
    public modalCtrl: ModalController,
    public menuCtrl: MenuController,
    public loadingCtrl: LoadingController,
  ) { }

  ngOnInit() {
  }


  async procesoLogin1(login) {

    if (!login.nit || !login.pass) {

      const alert2 = await this.alertCtrl.create({
        cssClass: 'alertas',
        mode: 'ios',
        message: 'Por favor verifique los datos ingresados.',
        buttons: ['Aceptar']
      });
      await alert2.present();

    }
    else {

      this.datalogin = this.fbDB.traeEmpresaNit(login.nit).valueChanges().subscribe(async data => {
        this.dataEmp = data;

        this.datalogin.unsubscribe();

        if (this.dataEmp.length === 0) {

          const alert2 = await this.alertCtrl.create({
            cssClass: 'alertas',
            mode: 'ios',
            message: 'No encontramos registro de su empresa, por favor verifique.',
            buttons: ['Aceptar']
          });
          await alert2.present();

        }
        else {
          this.dataEmp = this.dataEmp[0];

          this.estadoCuenta = null;

          if (this.dataEmp.emp_estado == 'activo') {

            let hoy = moment().format('YYYY-MM-DD');

            if (this.dataEmp['plan_fecha_fin'] <= hoy) {
              this.estadoCuenta = 'inactiva';
            }
            else {
              this.estadoCuenta = 'activo';
            }
          }
          else if (this.dataEmp.emp_estado == 'inactivo') {
            this.estadoCuenta = 'inactiva';
          }
          else if (this.dataEmp.emp_estado == 'bloqueado') {
            this.estadoCuenta = 'bloqueado';
          }
          else if (this.dataEmp.emp_estado == 'eliminado') {
            this.estadoCuenta = 'eliminado';
          }

          this.procesoLogin2(this.estadoCuenta, login);

        }

      })
    }

  }


  async procesoLogin2(estadoEmp, login) {
    const loading = await this.loadingCtrl.create({
      spinner: 'lines-sharp',
      mode: 'ios',
      backdropDismiss: false,
      cssClass: "loaderFull",
      message: 'Por favor espere'
    });
    loading.present();

    if (estadoEmp === 'inactivo') {

      let dataActuEmpresa = {
        emp_nit: login.nit,
        emp_estado: 'inactivo'
      }
      this.fbDB.actuEmp(dataActuEmpresa);

      const conex3 = this.fbDB.traePlanEmp('gratuito').subscribe(async (respGratuito) => {
        let dataPlanGratuito: any = respGratuito;
        let opcionesPlan = '<br><br><b>Ofertas:</b> ' + dataPlanGratuito.plan_ofer + '<br> <b>Ofertas Hoy</b> ' + dataPlanGratuito.plan_oferHoy + '<br> <b>Productos y Servicios</b>' + dataPlanGratuito.plan_ps

        loading.dismiss();
        const alert2 = await this.alertCtrl.create({
          cssClass: 'alertas',
          mode: 'ios',
          backdropDismiss: false,
          message: 'Cuenta Inactiva. <br><br>Esto sucede porque tu plan ya venció.... <br><br>Pero no te preocupes mientras tomas la decisión de renovación, te dejamos en el Plan gratuito donde podrás realizar las siguientes publicaciones: ' + opcionesPlan,
          buttons: [
            {
              text: 'Cancelar',
              handler: async () => {
                this, this.modalCtrl.dismiss();
              }
            }, {
              text: 'Aceptar Nuevo Plan',
              handler: async () => {

                let dataActuEmp = {
                  emp_nit: login.nit,
                  plan_ofer: dataPlanGratuito.plan_ofer,
                  plan_oferHoy: dataPlanGratuito.plan_oferHoy,
                  plan_ps: dataPlanGratuito.plan_ps,
                  plan_sede: dataPlanGratuito.plan_sedes,
                  emp_estado: 'activo',
                  plan_empresa: 'gratuito',
                  plan_fecha_ini_gratuito: moment().format('x'),
                  nuevaEmpVistaAdmin: false,
                  plan_fecha_fin: moment().add(30, 'days').format('x'),
                }
                this.fbDB.actuEmp(dataActuEmp).then(async (resp) => {

                  let loginBeforeActivated = moment().add(30, 'days').format('x');
                  this.storage.set('uhcloud_planId', String(this.dataEmp['plan_conv_id']));
                  this.storage.set('uhcloud_nit', this.dataEmp['emp_nit']);
                  this.storage.set('uhcloud_empN', this.dataEmp['emp_nom']);
                  this.storage.set('uhcloud_ld', moment().format('x'));
                  this.storage.set('uhcloud_lab', loginBeforeActivated);
                  this.storage.set('uhcloud_eidentificado', 'true');
                  this.storage.set('uhcloud_imagen', this.dataEmp['emp_img']);

                  let ultimoIngreso = moment().format("x");
                  this.storage.set('uhcloud_ui', ultimoIngreso)

                  this.sesionUser.setUserIdentificado();
                  this.sesionUser.setEmpUI(ultimoIngreso);
                  this.sesionUser.setEmpNom(this.dataEmp['emp_nom']);
                  this.sesionUser.setEmpImg(this.dataEmp['emp_img']);

                  if (this.plt.is("mobile")) {
                    window['plugins'].OneSignal.setExternalUserId(String(login.nit));
                  }

                  let fechaIniS = moment().format('YYYY-MM-DD hh:mm:ss a');
                  const info = await Device.getInfo();
                  this.fbDB.guardaDispositivo(info, this.dataEmp.nit, this.dispositivo, fechaIniS);

                  this.modalCtrl.dismiss();

                })

              }
            }
          ]
        });
        await alert2.present();
      })

    }
    else if (estadoEmp === 'bloqueado') {
      loading.dismiss();
      const alert2 = await this.alertCtrl.create({
        cssClass: 'alertas',
        mode: 'ios',
        message: 'Cuenta Bloqueada. <br><br>esto sucede porque has violado la politica de datos de Helpy, si lo desesas puedes comunicate con nostros para mayor información',
        buttons: ['Aceptar']
      });
      await alert2.present();

    }
    else if (estadoEmp === 'activo') {

      if (String(login.pass) == String(this.dataEmp.login.pass)) {
        this.storage.set('nit', this.dataEmp.nit);

        let fechaIniS = moment().format('YYYY-MM-DD hh:mm:ss a');
        const info = await Device.getInfo();
        this.fbDB.guardaDispositivo(info, this.dataEmp.nit, this.dispositivo, fechaIniS);

        if (this.plt.is("mobile")) {
          window['plugins'].OneSignal.setExternalUserId(String(login.nit));
        }

        let loginBeforeActivated = moment().add(30, 'days').format('x');
        this.storage.set('uhcloud_planId', String(this.dataEmp['plan_conv_id']));
        this.storage.set('uhcloud_nit', this.dataEmp['emp_nit']);
        this.storage.set('uhcloud_empN', this.dataEmp['emp_nom']);
        this.storage.set('uhcloud_ld', moment().format('x'));
        this.storage.set('uhcloud_lab', loginBeforeActivated);
        this.storage.set('uhcloud_eidentificado', 'true');
        this.storage.set('uhcloud_imagen', this.dataEmp['emp_img']);
        let ultimoIngreso = moment().format("x");
        this.storage.set('uhcloud_ui', ultimoIngreso)

        this.sesionUser.setUserIdentificado();
        this.sesionUser.setEmpUI(ultimoIngreso);
        this.sesionUser.setEmpNom(this.dataEmp['emp_nom']);
        this.sesionUser.setEmpImg(this.dataEmp['emp_img']);


        loading.dismiss();
        this.modalCtrl.dismiss();

      }
      else {
        loading.dismiss();
        const alert2 = await this.alertCtrl.create({
          cssClass: 'alertas',
          mode: 'ios',
          header: 'Datos errados',
          message: 'Por favor verifique la información suministrada.',
          buttons: ['Aceptar']
        });
        await alert2.present();
      }
    }
    else if (estadoEmp === 'eliminado') {

      loading.dismiss();
      const alert2 = await this.alertCtrl.create({
        cssClass: 'alertas',
        mode: 'ios',
        message: 'No encontramos registro de su empresa, por favor verifique. <br><br><br> Err:Del3t',
        buttons: ['Aceptar']
      });
      await alert2.present();

    }
  }


  async irTC() {
    this.menuCtrl.close();
    this.router.navigate(['tyc']);
    this.modalCtrl.dismiss();
  }


  async recPass() {
    this.modalCtrl.dismiss();
    const modal = await this.modalCtrl.create({
      component: AccesoRecpassPage,
      mode: 'ios',
      initialBreakpoint: 0.55,
      breakpoints: [0, 0.55, 0.65]
    });
    return await modal.present();

  }

  async modalRegisterOpen() {
    this.modalCtrl.dismiss();
    const modal = await this.modalCtrl.create({
      component: AccesoregistroPage,
      mode: 'ios',
      initialBreakpoint: 0.95
    });
    return await modal.present();
  }

  togglePasswordMode() {
    //cambiar tipo input
    this.passwordTypeInput = this.passwordTypeInput === 'text' ? 'password' : 'text';
    //obtener el input
    const nativeEl = this.passwordEye.nativeElement.querySelector('input');
    //obtener el indice de la posición del texto actual en el input
    const inputSelection = nativeEl.selectionStart;
    //ejecuto el focus al input
    nativeEl.focus();
    //espero un milisegundo y actualizo la posición del indice del texto
    setTimeout(() => {
      nativeEl.setSelectionRange(inputSelection, inputSelection);
    }, 1);
  }

}
