import { Component, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import * as moment from 'moment';
import { DbrealtimeService } from 'src/app/services/dbrealtime.service';
import { LocalstorageService } from 'src/app/services/localstorage.service';
import { SmsService } from 'src/app/services/sms.service';
import { MailsService } from 'src/app/services/mails.service';
import { SesionuserService } from 'src/app/services/sesionuser.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-accesoregistro',
  templateUrl: './accesoregistro.page.html',
  styleUrls: ['./accesoregistro.page.scss'],
})
export class AccesoregistroPage implements OnInit {

  prereg: any = [];
  numTarAct: any = null;
  lat: any;
  lng: any;
  dptos: any = null;
  dptosRadio: any = {};
  ciudadRadio: any = {};
  ciudades: any = null;
  ciudadSelectUser: any;
  deptoPosSelectUser: string = null;
  dptoArray: string = null;
  acumCat: any = [];
  categorias1: any = [];
  categorias: any = [];
  rNit: any = null;
  valNum: string;
  valMail: string;
  showRegistration: boolean = true;
  otpCreado: any = null;
  dataRegEmp: any = [];
  conseNuevo: any;
  conseactconv: any = null;

  constructor(
    public alertCtrl: AlertController,
    public storage: LocalstorageService,
    public modalCtrl: ModalController,
    public loadingCtrl: LoadingController,
    public sendSms: SmsService,
    public sendMail: MailsService,
    public fbDB: DbrealtimeService,
    public utils: UtilsService,
    public sesionUser: SesionuserService
  ) { }

  async ngOnInit() {

    fetch("assets/data/_deptos.json").then(res => res.json()).then(json => {
      this.dptos = json;

      for (let i = 0; i < this.dptos.length; i++) {
        this.dptosRadio[i] = {
          name: this.dptos[i],
          type: 'radio',
          label: this.dptos[i],
          value: i
        }
      }

      this.dptosRadio = Object["values"](this.dptosRadio);

    });


    fetch("assets/data/_ciudades.json").then(res => res.json()).then(json => {
      this.ciudades = json;
    });


    this.fbDB.traeCategoria().subscribe((resp) => {
      this.categorias1 = resp;

      for (let i = 0; i < this.categorias1.length; i++) {
        this.acumCat[i] = this.categorias1[i]['categoria'];
      }

      this.acumCat.sort(function (a, b) {
        var n = a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase());
        return n === 0 && a !== b ? b.localeCompare(a) : n;
      });

      this.categorias = this.acumCat;

    });

  }


  async ciudadSelect() {

    const alert = await this.alertCtrl.create({
      cssClass: 'alertas',
      mode: 'ios',
      backdropDismiss: false,
      header: 'Selecciona el Departamento',
      inputs: this.dptosRadio,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: () => {
          }
        }, {
          text: 'Siguiente',
          handler: async (resp) => {

            this.deptoPosSelectUser = resp;

            for (let i = 0; i < this.ciudades[resp].ciudades.length; i++) {
              this.ciudadRadio[i] = {
                name: this.ciudades[resp].ciudades[i],
                type: 'radio',
                label: this.ciudades[resp].ciudades[i],
                value: this.ciudades[resp].ciudades[i]
              }
            }

            this.ciudadRadio = Object["values"](this.ciudadRadio);

            const alert2 = await this.alertCtrl.create({
              cssClass: 'alertas',
              mode: 'ios',
              backdropDismiss: false,
              header: 'Selecciona La Ciudad',
              inputs: this.ciudadRadio,
              buttons: [
                {
                  text: 'Cancelar',
                  role: 'cancel',
                  handler: () => {
                  }
                }, {
                  text: 'Siguiente',
                  handler: async (respu) => {
                    this.prereg.emp_ciudad = respu;
                  }
                }
              ]

            });
            await alert2.present();

          }
        }
      ]
    });
    await alert.present();

  }


  async preregUser(dat) {

    if ((!dat.repre_nom) || (!dat.emp_nom) || (!dat.emp_desc) || (!dat.emp_nit) || (!dat.emp_ciudad)
      || (!dat.tagppal) || (!dat.emp_correo) || (!dat.emp_cel) || (!dat.pass) || (!dat.pass2)) {

      const alert = await this.alertCtrl.create({
        cssClass: 'alertas',
        mode: 'ios',
        subHeader: 'Por favor verifique, todos los campos son obligatorios',
        buttons: ['Aceptar']
      });
      await alert.present();

    }
    else {

      const loading = await this.loadingCtrl.create({
        spinner: 'lines-sharp',
        mode: 'ios',
        backdropDismiss: false,
        cssClass: "loaderFull",
        message: 'Por favor espere'
      });
      loading.present();

      dat.emp_nit = String(dat.emp_nit);

      this.rNit = null;
      const respNit = this.fbDB.buscaNit(dat.emp_nit).subscribe(async resp => {
        this.rNit = resp;
        respNit.unsubscribe();

        if (this.rNit) {
          loading.dismiss();
          const alert = await this.alertCtrl.create({
            cssClass: 'alertas',
            mode: 'ios',
            subHeader: 'El Documento/Nit suministrado ya se encuentra regsitrado en Helpy cloud, por favor valida la información suministrada',
            buttons: ['Aceptar']
          });
          await alert.present();

        }
        else {

          this.valNum = 'si';
          let cel = String(dat.emp_cel);
          let cantNumCel = cel.length;
          let primerNumCel = cel.substring(0, 1);

          if (cantNumCel != 10) {
            this.valNum = 'no'
          }
          if (primerNumCel != '3') {
            this.valNum = 'no'
          }

          if (this.valNum === "no") {

            loading.dismiss();
            const alert = await this.alertCtrl.create({
              cssClass: 'alertas',
              mode: 'ios',
              subHeader: 'Número de celular no valido',
              buttons: ['Aceptar']
            });
            await alert.present();

          }
          else {

            this.valMail = 'si';
            let arrobaMail = (dat.emp_correo).toString().indexOf('@');
            let validacorreo = arrobaMail === -1 ? false : true;

            if (!validacorreo) {

              loading.dismiss();
              const alert = await this.alertCtrl.create({
                cssClass: 'alertas',
                mode: 'ios',
                subHeader: 'Correo electronico no valido',
                buttons: ['Aceptar']
              });
              await alert.present();

            }
            else {

              if (String(dat.pass) === String(dat.pass2)) {

                this.dataRegEmp = {
                  _esta_cel: 0,
                  _esta_comoll: 0,
                  _esta_face: 0,
                  _esta_fav: 1,
                  _esta_insta: 0,
                  _esta_mail: 0,
                  _esta_megus: 1,
                  _esta_qr: 0,
                  _esta_tel: 0,
                  _esta_web: 0,
                  _esta_what: 0,
                  bonos: "si",
                  emp_cel: dat.emp_cel,
                  emp_ciudad: dat.emp_ciudad,
                  emp_correo: dat.emp_correo,
                  emp_desc: dat.emp_desc,
                  emp_dir_ppal: 'Sin Info',
                  emp_dir_ppal_1: " ",
                  emp_dir_ppal_2: " ",
                  emp_dir_ppal_3: " ",
                  emp_dir_ppal_4: " ",
                  emp_dir_ppal_lat: "N/A",
                  emp_dir_ppal_lon: "N/A",
                  emp_estado: "activo",
                  emp_img: "no",
                  emp_inicia: moment().format('YYYY-MM-DD'),
                  emp_registro_gratis: moment().format('YYYY-MM-DD hh:mm a'),
                  emp_nit: String(dat.emp_nit),
                  emp_nom: dat.emp_nom,
                  emp_pagina_web: 'Sin Info',
                  emp_tel: dat.emp_cel,
                  hor_domingo_fin: "Cerrado",
                  hor_domingo_ini: "Cerrado",
                  hor_jueves_fin: "6:00 pm",
                  hor_jueves_ini: "7:00 am",
                  hor_lunes_fin: "6:00 pm",
                  hor_lunes_ini: "7:00 am",
                  hor_martes_fin: "6:00 pm",
                  hor_martes_ini: "7:00 am",
                  hor_miercoles_fin: "6:00 pm",
                  hor_miercoles_ini: "7:00 am",
                  hor_sabado_fin: "12:00 pm",
                  hor_sabado_ini: "8:00 am",
                  hor_viernes_fin: "6:00 pm",
                  hor_viernes_ini: "7:00 am",
                  link_pago: "Sin Info",
                  megusta_cant: 1,
                  plan_empresa: "Gratuito",
                  plan_fecha_fin: moment().add(90, 'days').format('YYYY-MM-DD'),
                  plan_fecha_ini: moment().format('YYYY-MM-DD'),
                  repre_cel: dat.emp_cel,
                  repre_correo: dat.emp_correo,
                  repre_nom: dat.repre_nom,
                  repre_num_doc: 'Sin Info',
                  repre_tipo_doc: 'Sin Info',
                  rs_face: 'Sin Info',
                  rs_insta: 'Sin Info',
                  rs_what: dat.emp_cel,
                  tagppal: dat.tagppal,
                  tagsec: null,
                  login: {
                    estado: 'pre-activo',
                    nit: String(dat.emp_nit),
                    pass: String(dat.pass),
                  },
                  insc_usu: moment().format('YYYY-MM-DD'),
                  plan_fecha_ini_gratuito: moment().format('x'),
                  nuevaEmpVistaAdmin: false,
                }

                loading.dismiss();
                this.showRegistration = false;

                this.otpCreado = this.generateRandomString(6);
                this.otpCreado = this.otpCreado.trim();
                console.log(this.otpCreado);

                let msj = 'Para activar tu cuenta en Helpy Cloud, ingresa el siguiente codigo: ' + this.otpCreado;

                this.sendSms.initSendSms({ phoneNumber: String(dat.emp_cel), message: msj });

                let txtMail = await this.utils.txtMailCodigoOTP({ otp: this.otpCreado })
                let dataSendMailCodigoOTP = {
                  title: `Tu código de seguridad de Helpy`,
                  body: txtMail,
                  recipients: dat.emp_correo,
                  template: 'Helpy_otp',
                  attachments: []
                }
                await this.sendMail.initSendEmail(dataSendMailCodigoOTP);

              }
              else {

                loading.dismiss();
                const alert = await this.alertCtrl.create({
                  cssClass: 'alertas',
                  mode: 'ios',
                  subHeader: 'La validación de la clave para inicio de sesión no conincide, por favor verifique',
                  buttons: ['Aceptar']
                });
                await alert.present();

              }

            }

          }
        }
      })

    }

  }



  generateRandomString(num) {
    const characters = '12345678901234567890';
    let result1 = ' ';
    const charactersLength = characters.length;
    for (let i = 0; i < num; i++) {
      result1 += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result1;
  }



  async finalizarReg(ev) {

    if (ev.length === 6) {

      const loading = await this.loadingCtrl.create({
        spinner: 'lines-sharp',
        mode: 'ios',
        backdropDismiss: false,
        cssClass: "loaderFull",
        message: 'Por favor espere',
      });
      loading.present();

      if (ev === this.otpCreado) {

        const conex1 = this.fbDB.conseActConve().subscribe(data => {
          this.conseactconv = data;
          this.conseNuevo = this.conseactconv + 1;
          conex1.unsubscribe();
          this.fbDB.conseActualizarConve(this.conseNuevo);

          const conex2 = this.fbDB.traePlanEmp('gratuito').subscribe(async (respPlan) => {
            let dataPlan: any = respPlan;
            conex2.unsubscribe();

            this.dataRegEmp['plan_ofer'] = dataPlan.plan_ofer;
            this.dataRegEmp['plan_oferHoy'] = dataPlan.plan_oferHoy;
            this.dataRegEmp['plan_ps'] = dataPlan.plan_ps;
            this.dataRegEmp['plan_sedes'] = dataPlan.plan_sedes;

            this.fbDB.creaEmpresa(this.dataRegEmp).then(async (resp) => {

              let pushenv = {
                titulo: '🥳 Helpy Cloud Activado!!! 🎉',
                msjPush: 'Felicitaciones, Ya haces parte de Helpy Cloud, 🤩 Ahora tu negocio esta a otro nivel!',
                destino: this.dataRegEmp['emp_nit'],
                tipo: '1_empresa',
                ciudad: null,
                img: null
              }
              this.fbDB.enviarPushOSignal(pushenv);

              let pushenvAdmin = {
                titulo: 'Nuevo registro Helpy Cloud',
                msjPush: 'Empresa: ' + this.dataRegEmp['emp_nom'],
                destino: null,
                tipo: 'admin',
                ciudad: null,
                img: null
              }
              this.fbDB.enviarPushOSignal(pushenvAdmin);

              let loginBeforeActivated = moment().add(30, 'days').format('x');
              this.storage.set('uhcloud_planId', this.dataRegEmp['plan_conv_id']);
              this.storage.set('uhcloud_nit', this.dataRegEmp['emp_nit']);
              this.storage.set('uhcloud_empN', this.dataRegEmp['emp_nom']);
              this.storage.set('uhcloud_ld', moment().format('x'));
              this.storage.set('uhcloud_lab', loginBeforeActivated);
              this.storage.set('uhcloud_eidentificado', 'true');
              this.storage.set('uhcloud_imagen', this.dataRegEmp['emp_img']);
              let ultimoIngreso = moment().format("x");
              this.storage.set('uhcloud_ui', ultimoIngreso)

              this.sesionUser.setEmpUI(ultimoIngreso);
              this.sesionUser.setUserIdentificado();
              this.sesionUser.setEmpNom(this.dataRegEmp['emp_nom']);
              this.sesionUser.setEmpImg(this.dataRegEmp['emp_img']);

              loading.dismiss();
              const alert = await this.alertCtrl.create({
                cssClass: 'alertas',
                mode: 'ios',
                backdropDismiss: false,
                message: 'Ya tienes tu perfil comercial, en Helpy Cloud. <br><br> Recuerda ingresar a la opción <b>Mi perfil</b> para que registres toda la información de tu negocio, y empieces a publicar tus productos y servicios.',
                buttons: ['Aceptar']
              });
              await alert.present();
              this.modalCtrl.dismiss();

            })
          })
        })

      }
      else {
        loading.dismiss();
        const alert = await this.alertCtrl.create({
          cssClass: 'alertas',
          mode: 'ios',
          message: 'Código errado, por favor verifique',
          buttons: ['Aceptar']
        });
        await alert.present();
      }

    }

  }

}